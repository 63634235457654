import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
  DataTable,
  DataTableRow,
  DataTableHeader,
  DataTableBody,
  DataTableValueBlock,
  DataTableCodeBlock,
  PageNavigation,
  Paragraph,
  PlatformTab,
  Section,
  SectionSubhead,
} from '../../../../components';

const directions = [
  'All',
  'Vertical',
  'Top',
  'Bottom',
  'Horizontal',
  'Left',
  'Right',
];

const WebTab = () => {
  const data = useStaticQuery(graphql`
    query SpacesWebResourcesCodeHelpers {
      allSpacesYaml {
        edges {
          node {
            spaces {
              name
              value
              css_var
              rn_var
            }
          }
        }
      }
    }
  `);

  return (
    <PlatformTab>
      <PageNavigation links={['Margin Classes', 'Padding Classes']} />

      <Section title="Margin Classes">
        <Paragraph>
          Add these margin classes to your markup to add space to the outside or
          between items.
        </Paragraph>
        {directions.map(direction => {
          return (
            <div className="uni-margin--two--top" key={direction}>
              <SectionSubhead>{direction}</SectionSubhead>
              <DataTable>
                <DataTableHeader>
                  <DataTableRow layout="thirds">
                    <th>Class Name</th>
                    <th>Value</th>
                    <th>Associated Variable</th>
                  </DataTableRow>
                </DataTableHeader>
                <DataTableBody>
                  {data.allSpacesYaml.edges[0].node.spaces.map(row => {
                    const classValue = `uni-margin--${row.css_var
                      .replace('var(--space-', '')
                      .replace(')', '')}${direction !== 'All' ?
                      `--${direction.toLowerCase()}` : ''}`;
                    return (
                      <DataTableRow layout="thirds" key={row.css_var}>
                        <DataTableCodeBlock>{classValue}</DataTableCodeBlock>
                        <DataTableValueBlock>{row.value}</DataTableValueBlock>
                        <DataTableCodeBlock>{row.css_var}</DataTableCodeBlock>
                      </DataTableRow>
                    );
                  })}
                </DataTableBody>
              </DataTable>
            </div>
          );
        })}
      </Section>

      <Section title="Padding Classes">
        <Paragraph>
          Add these padding classes to your markup to add space to the inside of
          items.
        </Paragraph>
        {directions.map(direction => {
          return (
            <div className="uni-margin--two--top" key={direction}>
              <SectionSubhead>{direction}</SectionSubhead>
              <DataTable>
                <DataTableHeader>
                  <DataTableRow layout="thirds">
                    <th>Class Name</th>
                    <th>Value</th>
                    <th>Associated Variable</th>
                  </DataTableRow>
                </DataTableHeader>
                <DataTableBody>
                  {data.allSpacesYaml.edges[0].node.spaces.map(row => {
                    const classValue = `uni-padding--${row.css_var
                      .replace('var(--space-', '')
                      .replace(')', '')}${direction !== 'All' ?
                      `--${direction.toLowerCase()}` : ''}`;
                    return (
                      <DataTableRow layout="thirds" key={row.css_var}>
                        <DataTableCodeBlock>{classValue}</DataTableCodeBlock>
                        <DataTableValueBlock>{row.value}</DataTableValueBlock>
                        <DataTableCodeBlock>{row.css_var}</DataTableCodeBlock>
                      </DataTableRow>
                    );
                  })}
                </DataTableBody>
              </DataTable>
            </div>
          );
        })}
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
