import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
  DataTable,
  DataTableRow,
  DataTableHeader,
  DataTableBody,
  DataTableValueBlock,
  DataTableCodeBlock,
  PlatformTab,
  Section,
  SectionSubhead,
} from '../../../../components';

const directions = [
  'All',
  'Vertical',
  'Top',
  'Bottom',
  'Horizontal',
  'Left',
  'Right',
];

const ReactNativeTab = () => {
  const data = useStaticQuery(graphql`
    query SpacesRNResourcesCodeHelpers {
      allSpacesYaml {
        edges {
          node {
            spaces {
              name
              value
              css_var
              rn_var
            }
          }
        }
      }
    }
  `);

  return (
    <PlatformTab>
      <Section>
        {directions.map(direction => {
          if (direction === 'Horizontal' || direction === 'Vertical') {
            return;
          }

          // eslint-disable-next-line consistent-return
          return (
            <div className="uni-margin--two--top">
              <SectionSubhead>{direction}</SectionSubhead>
              <DataTable>
                <DataTableHeader>
                  <DataTableRow layout="halves">
                    <th>Class Name</th>
                    <th>Value</th>
                  </DataTableRow>
                </DataTableHeader>
                <DataTableBody>
                  {data.allSpacesYaml.edges[0].node.spaces.map(row => {
                    const varName =
                      direction === 'All'
                        ? `${row.rn_var}`
                        : `${row.rn_var}${direction}`;
                    return (
                      <DataTableRow layout="halves" key={row.rn_var}>
                        <DataTableCodeBlock>{varName}</DataTableCodeBlock>
                        <DataTableValueBlock>{row.value}</DataTableValueBlock>
                      </DataTableRow>
                    );
                  })}
                </DataTableBody>
              </DataTable>
            </div>
          );
        })}
      </Section>
    </PlatformTab>
  );
};

export default ReactNativeTab;
